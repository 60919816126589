@font-face {
	font-family: "iconfont";
	font-display: block;
	src: url("/_next/static/assets/fonts/iconfont.70b488f6bd.woff2") format("woff2");
}

.icon {
	line-height: 1;
}

.icon:before {
	font-family: iconfont !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}

.icon-alt-check:before {
	content: "\f101";
}
.icon-angle-down:before {
	content: "\f102";
}
.icon-arrow-down:before {
	content: "\f103";
}
.icon-arrow-link:before {
	content: "\f104";
}
.icon-back:before {
	content: "\f105";
}
.icon-bullet:before {
	content: "\f106";
}
.icon-bulletb:before {
	content: "\f107";
}
.icon-bulletm:before {
	content: "\f108";
}
.icon-bullett:before {
	content: "\f109";
}
.icon-caption-off-boxed:before {
	content: "\f10a";
}
.icon-caption-off:before {
	content: "\f10b";
}
.icon-caption-on-boxed:before {
	content: "\f10c";
}
.icon-caption-on:before {
	content: "\f10d";
}
.icon-caret:before {
	content: "\f10e";
}
.icon-check:before {
	content: "\f10f";
}
.icon-chevron-bold-down:before {
	content: "\f110";
}
.icon-chevron-left:before {
	content: "\f111";
}
.icon-chevron-light-down:before {
	content: "\f112";
}
.icon-chevron-light-up:before {
	content: "\f113";
}
.icon-chevron-right:before {
	content: "\f114";
}
.icon-close-full-btn:before {
	content: "\f115";
}
.icon-close:before {
	content: "\f116";
}
.icon-cnbc-white:before {
	content: "\f117";
}
.icon-cnbc:before {
	content: "\f118";
}
.icon-color-logo-badge:before {
	content: "\f119";
}
.icon-dot:before {
	content: "\f11a";
}
.icon-ellipsis:before {
	content: "\f11b";
}
.icon-email:before {
	content: "\f11c";
}
.icon-embed:before {
	content: "\f11d";
}
.icon-external-link-arrow:before {
	content: "\f11e";
}
.icon-external-link:before {
	content: "\f11f";
}
.icon-facebook:before {
	content: "\f120";
}
.icon-fallback-author-msnbc:before {
	content: "\f121";
}
.icon-fallback-author-nbcnews:before {
	content: "\f122";
}
.icon-fallback-author-today:before {
	content: "\f123";
}
.icon-fast-channel-live-badge:before {
	content: "\f124";
}
.icon-feedback:before {
	content: "\f125";
}
.icon-full-screen-alt:before {
	content: "\f126";
}
.icon-full-screen-close-alt:before {
	content: "\f127";
}
.icon-full-screen-close:before {
	content: "\f128";
}
.icon-full-screen:before {
	content: "\f129";
}
.icon-gallery:before {
	content: "\f12a";
}
.icon-generic-thumbnail:before {
	content: "\f12b";
}
.icon-heart-border-thick:before {
	content: "\f12c";
}
.icon-heart-border:before {
	content: "\f12d";
}
.icon-heart-filled:before {
	content: "\f12e";
}
.icon-heart-thick:before {
	content: "\f12f";
}
.icon-heart:before {
	content: "\f130";
}
.icon-info:before {
	content: "\f131";
}
.icon-instagram:before {
	content: "\f132";
}
.icon-link:before {
	content: "\f133";
}
.icon-live-with-dot:before {
	content: "\f134";
}
.icon-location:before {
	content: "\f135";
}
.icon-lock:before {
	content: "\f136";
}
.icon-mastodon:before {
	content: "\f137";
}
.icon-msnbc-tv:before {
	content: "\f138";
}
.icon-msnbc-tve:before {
	content: "\f139";
}
.icon-msnbc:before {
	content: "\f13a";
}
.icon-nbc-logo:before {
	content: "\f13b";
}
.icon-nbc-news-logo-vertical:before {
	content: "\f13c";
}
.icon-nbc-news-now-update-white:before {
	content: "\f13d";
}
.icon-nbcnews-white:before {
	content: "\f13e";
}
.icon-noticias-ahora-horizontal:before {
	content: "\f13f";
}
.icon-pause:before {
	content: "\f140";
}
.icon-peacock-color:before {
	content: "\f141";
}
.icon-peacock:before {
	content: "\f142";
}
.icon-phone:before {
	content: "\f143";
}
.icon-picture-in-picture-icon:before {
	content: "\f144";
}
.icon-pinterest:before {
	content: "\f145";
}
.icon-play-btn-watch-badge:before {
	content: "\f146";
}
.icon-play:before {
	content: "\f147";
}
.icon-playlist:before {
	content: "\f148";
}
.icon-print:before {
	content: "\f149";
}
.icon-quote-mark:before {
	content: "\f14a";
}
.icon-remove-circle:before {
	content: "\f14b";
}
.icon-remove:before {
	content: "\f14c";
}
.icon-replay:before {
	content: "\f14d";
}
.icon-save-border-thick:before {
	content: "\f14e";
}
.icon-save-border:before {
	content: "\f14f";
}
.icon-save-circle:before {
	content: "\f150";
}
.icon-save-filled:before {
	content: "\f151";
}
.icon-save-hover:before {
	content: "\f152";
}
.icon-save-thick:before {
	content: "\f153";
}
.icon-save:before {
	content: "\f154";
}
.icon-search:before {
	content: "\f155";
}
.icon-share-dot:before {
	content: "\f156";
}
.icon-share-email:before {
	content: "\f157";
}
.icon-share-facebook:before {
	content: "\f158";
}
.icon-share-icon-v2:before {
	content: "\f159";
}
.icon-share-icon:before {
	content: "\f15a";
}
.icon-share-pinterest:before {
	content: "\f15b";
}
.icon-share-print:before {
	content: "\f15c";
}
.icon-share-sms:before {
	content: "\f15d";
}
.icon-shop:before {
	content: "\f15e";
}
.icon-signal:before {
	content: "\f15f";
}
.icon-slideshow:before {
	content: "\f160";
}
.icon-sms:before {
	content: "\f161";
}
.icon-snapchat:before {
	content: "\f162";
}
.icon-star-half:before {
	content: "\f163";
}
.icon-star-outline:before {
	content: "\f164";
}
.icon-star:before {
	content: "\f165";
}
.icon-telemundo-t:before {
	content: "\f166";
}
.icon-text:before {
	content: "\f167";
}
.icon-threads:before {
	content: "\f168";
}
.icon-tiktok:before {
	content: "\f169";
}
.icon-today-sunrise:before {
	content: "\f16a";
}
.icon-today:before {
	content: "\f16b";
}
.icon-triangle-feather:before {
	content: "\f16c";
}
.icon-twitter:before {
	content: "\f16d";
}
.icon-unauthenticated-save:before {
	content: "\f16e";
}
.icon-vertical-better:before {
	content: "\f16f";
}
.icon-vertical-globalcitizen:before {
	content: "\f170";
}
.icon-vertical-leftfield:before {
	content: "\f171";
}
.icon-vertical-mach:before {
	content: "\f172";
}
.icon-vertical-msnbc:before {
	content: "\f173";
}
.icon-vertical-news:before {
	content: "\f174";
}
.icon-vertical-think:before {
	content: "\f175";
}
.icon-vertical-today:before {
	content: "\f176";
}
.icon-video:before {
	content: "\f177";
}
.icon-volume-level-0:before {
	content: "\f178";
}
.icon-volume-level-1:before {
	content: "\f179";
}
.icon-volume-level-2:before {
	content: "\f17a";
}
.icon-whatsapp:before {
	content: "\f17b";
}
.icon-xfinity:before {
	content: "\f17c";
}
.icon-youtube:before {
	content: "\f17d";
}
